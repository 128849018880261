@import './sass/variables';

* {
  margin: 0;
  padding: 0;
}




