@import '../../sass/variables', '../../sass/mixins';

.GlobalMessage {
  padding: 5px;
  p {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    // border: 1px solid #fff;
    border: 1px solid $util-color-2;
    padding: 10px;
    margin: 10px 0px 5px 0px;
    color: #fff;
    display: inline-block;
    word-break: normal;
    white-space: normal;
    align-items: center;
    .messenger-name-sender, .messenger-name-receiver {
      margin-right: 10px;
    }
    .messenger-name-receiver {
      color: $util-color;
    }
    .messenger-name-sender {
      color: #56bdf0;
    }
  }
}