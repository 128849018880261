@import '../../sass/mixins', '../../sass/variables';

.profile-user-image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: cover;
}
.comment-user-image-container {
  border-radius: 100%;
  border: 1px solid $util-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 15px;
  width: 60px;
  height: 60px;
  .comment-user-image {
    object-fit: cover;
    border-radius: 100%;
    width: 60px;
    height: 60px;
  }
}
.post-user-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border: 1px solid $util-color;
  border-radius: 100%;
  object-fit: cover;
}
.post-image {
  display: block;
  width: 100%;
  max-height: 300px;
}
.profile-card-user-image {
  border-radius: 100%;
  width: 125px;
  height: 125px;
  object-fit: cover;
}
.friend-list-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}
.inbox-user-image {
  width: 75px;
  height: 75px;
  border-radius: 100%;
 }
.message-profile-image {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;
}
