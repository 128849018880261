@import '../../sass/mixins';

.Posts {
  margin-top: -20px;
}
.posts-container {
  padding-top: 100px;
}
.posts-home-styles {
  
}

.profile-position {
  margin: -20px;
}