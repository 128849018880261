@import '../../sass/variables', '../../sass/mixins';

.Comment {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .comment-user-image-default-container, .comment-user-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $util-color;
    border-radius: 100%;
    color: $util-color;
    margin: 0px 0px 0px 15px;
    width: 60px;
    height: 60px;
    .comment-user-icon {
      font-size: 2em;
    }
  }
  article {
    display: flex;
    margin-right: 5px;
    .comment-container {
      width: 65%;
      p {
        width: 100%;
        display: inline-block;
        word-break: normal;
        white-space: normal;
        align-items: center;
        background-color: #fff;
        padding: 5px;
        font-size: 1em;
        margin: 5px 5px 5px 10px;
        @include border-radius;
        .comment-username {
          display: inline-block;
          margin-right: 5px;
          color: $util-color-2;
          font-weight: 600;
        }
      }
      .comment-details {
        width: 100%;
        display: flex;
        font-size: .8em;
        margin-top: 5px;
        margin-left: 10px;
        color: $util-color-2;
        .comment-date {
          margin-right: 10px;
        }
        .comment-time {
          margin-right: 10px;
        }
        .comment-edit-tag {

        }
      }
    }
  }
  .comment-options-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    .comment-options-icon {
      color: $util-color;
      margin-right: 5px;
    }
  }
}