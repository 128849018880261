@import '../../sass/mixins', '../../sass/variables';

.comment-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  @include border-radius;
  @include component-color;
  border: 1px solid $util-color;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  padding: 10px;
  .comment-delete-icon {
     color: $util-color;
     margin-right: 5px;
     font-size: 1.3em;
  }
}
