@import '../../sass/mixins', '../../sass/variables';

.UserInfo {
  background-color: $dark;
  padding: 10px;
  margin-top: -1px;
  margin-bottom: 15px;
  .user-info-container {
    .user-info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $util-color;
      padding-bottom: 10px;
      .intro {
        display: flex;
        align-items: flex-start;
        align-items: center;
        .intro-icon {
          color: $util-color;
          margin-right: 10px;
          font-size: 1.5em;
        }
        h2 {
          color: #fff;
        }
      }
      .edit-user-icon {
        color: $util-color;
        font-size: 1.3em;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
    }
    .about-me-title {
      display: flex;
      align-items: flex-end; 
      margin-top: 20px;
      .about-me-icon {
        color: $util-color;
        margin-right: 5px;
        font-size: 1.2em;
      }
      h3 {
        color: #fff;
      }
    }
    .about-me {
      color: #fff;
      padding: 10px;
    }
    .my-posts-title {
      display: flex;
      margin-top: 20px;
      .my-posts-icon {
        color: $util-color;
        font-size: 1.5em;
        margin-right: 10px;
      }
      h2 {
        color: #fff;
      }
    }
  }
}