@import '../../sass/variables', '../../sass/mixins';

.user-info-list-item {
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  color: #fff;
  margin-top: 10px;
  .user-information-icons {
    display: flex;
    justify-content: center;
    width: 20px;
    color: $util-color;
    font-size: 1.2em;
    margin-right: 10px;
  }
  label {
    font-weight: 600;
    font-size: 1.2em;
  }
  .user-info-item-data {
    font-size: 1.2em;
    margin-left: 10px;
  }
}