@import '../../sass/variables', '../../sass/mixins';

.PostAdd {
  .add-post-container {
    @include component-color;
    @include border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10000;
    box-shadow: 0px 0px 100px 50px #000;
    position: fixed;
    padding: 20px;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;  
      .create-post-icon {
        color: $util-color;
        font-size: 2em;
        margin-right: 10px;
      }
      h2 {
        color: #fff;
      }
    }
    label {
      color: #fff;
      margin: 10px 0px 5px 0px;
    }
    .text-area-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title-area{
        margin-bottom: 10px;
      }
      textarea {
        @include input-style;
        @include box-shadow;
        @include border-radius;
      }
      textarea:focus {
        @include input-focus;
      }
    }

    .post-buttons-container {
      display: flex;
      align-items: center;
      margin-top: 35px;
      justify-content: space-around;
      .add-post-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border: 2px solid $util-color;
        border-radius: 3px;
        color: #fff;
        background-color: $dark;
        font-weight: 600;
        font-size: 1em;
        width: 60%;
        .add-post-plus-icon {
          font-size: 1.3em;
          margin-right: 5px;
          color: $util-color;
        }
      }
      .cancel-post-button {
        border: none;
        color: $util-color-2;
        font-size: 1em;
        font-weight: 600;
      }
    }
  }
  .posts-toolbar {
    display: flex;
    align-items: center;
    background-color: $dark;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 25px;
    z-index: 1;
    .posts-icon {
      color: $util-color;
      font-size: 1em;
      margin-right: 10px;
    }
    h3 {
      color: #fff;
    }
    .post-add {
        background-color: $dark;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid $util-color;
        border-radius: 3px;
        padding: 5px;
        color: #fff;
        font-size: 1em;
      .add-post-icon {
        color: $util-color-2;
        margin-right: 5px;
      }
      h3 {
        color: #fff;
      }
    }
  }
}
.home-position {
  margin: 0px 0px 30px 0px;
  width: 100%;
  position: fixed;
}

.profile-position {
  margin: 0px 0px 20px 0px;
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .PostAdd {
    width: 100%;
  }
  .home-position {
    margin: 0px auto 30px auto;
  }
  
  .profile-position {
    margin: 0px auto 20px auto;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .PostAdd {
    // width: 100%;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .PostAdd {
    // width: 40%;
  }
}