@import '../../sass/variables', '../../sass//mixins';

.Post {
  @include box-shadow;
  @include border-radius;
  background-color: $dark;
  margin: 10px 10px 30px 10px;
  header {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    .post-user-container {
      display: flex;
      align-items: center;
      .post-user-image-default {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border: 1px solid $util-color;
        border-radius: 100%;
        .post-user-default-icon {
          font-size: 2.5em;
          color: $util-color;
        }
      }
      span{
        margin-right: 10px;
      }
      .post-username {
        color: #fff;
        font-weight: 700;
        font-size: 1.2em;
        .post-date-time {
          color: $util-color-2;
          font-size: .7em;
        }
      }
    }
    .post-date {
    }
    .post-time {
    }
    .post-options-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      .post-dropdown {
        color: $util-color;
        font-size: 1.5em;
      }
    }
  }
  .post-content {
    background-color: #fff;
    h4 {
      padding: 10px 10px 3px 10px;
    }
    p {
      padding: 0px 10px 10px 10px;
    }
  }
  footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    .post-info-container {
      display: flex;
      margin: 10px 0px 10px 0px;
      span {
        color: #fff;
      }
    }
    .post-actions-container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .post-like-container {
        display: flex;
        align-items: center;
        .post-like-button {
          color: #fff;
          cursor: pointer;
          @include border-radius;
          @include letter-spacing;
          font-weight: 500;
          padding: .3em;
          .post-like-icon {
            font-size: 1.2em;
            margin-right: 5px;
            color: $util-color;
          }
        }
        .post-dislike-button {
          color: #fff;
          cursor: pointer;
          @include border-radius;
          @include letter-spacing;
          font-weight: 500;
          padding: .3em;
          .post-dislike-icon {
            font-size: 1.2em;
            margin-right: 5px;
            color: $util-color;
          }
        }
      }
    }
    .post-comment-container {
      display: flex;
      align-items: center;
      .post-comments-button {
        color: #fff;
        cursor: pointer;
        @include border-radius;
        @include letter-spacing;
        font-weight: 500;
        padding: .3em;
        .post-comments-icon {
          font-size: 1.2em;
          margin-right: 5px;
          color: $util-color;
        }
      }
    }
  }
}


/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {
  .Post {
    
  }
}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Post {
    width: 60%;
    margin: 20px auto 30px auto;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Post {
    width: 50%;
    margin: 20px auto 30px auto;
  } 
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .Post {
    width: 40%;
    margin: 20px auto 30px auto;
  }
}