@import '../../sass/variables', '../../sass/mixins';

.NoContentMessage {
  padding-top: 70px;
  .message-container {
    background-color: $dark;
    @include border-radius;
    @include box-shadow;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 20px 0px 20px;
    height: 300px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      border: 1px solid $util-color;
      padding: 10px;
      border-radius: 5px;
      font-size: 1.3em;
      color: #fff;
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .NoContentMessage {
    width: 60%;
    margin: 20px auto
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .NoContentMessage {
    width: 50%;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .NoContentMessage {
    width: 40%;
  }
}