@import '../../sass/variables', '../../sass/mixins';

.CommentAdd {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    width: 90%;
    padding: 10px;
    input {
      @include border-radius;
      @include box-shadow;
      border: none;
      padding: 5px;
      margin-right: 10px;
      width: 80%;
      font-size: medium;
      caret-color: $util-color;
    }
    input:focus {
      @include input-focus;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border: none;
      @include box-shadow;
      @include border-radius;
      background-color: $util-color;
      color: #fff;
      font-size: 1em;
      font-weight: 600;
      .comment-send-icon {
        margin-right: 5px;
      }
    }
  }
}