@import '../../sass/variables';

.ts-particles {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
}

.background-color {
  background-color: #fff;
}