@import '../../sass/mixins', '../../sass/variables';

.post-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  @include component-color;
  @include border-radius;
  border: 1px solid $util-color;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  padding: 10px;
  .post-delete-icon {
    color: $util-color;
    margin-right: 5px;
    font-size: 1.3em;
 }
}