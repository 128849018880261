@import '../../sass/variables', '../../sass/mixins';

.friend-list-item {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 5px;
  .select-friend-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $util-color;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    .friend-list-profile-img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      object-fit: cover;
    }
    .friend-list-default-img {
      color: $util-color; 
      font-size: 1.3em;      
    }
  }
  .dropdown-list-name {
    display: flex;
    font-weight: 500;
  }
}