@import '../../sass/mixins', '../../sass/variables';

.FriendAdd {
  .request-sent {
    @include request-sent-style;
    .request-sent-icon {
      @include request-sent-icon;
    }
  }
  .add-friend-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark;
    border: 1px solid $util-color;
    padding: 10px;
    @include border-radius;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    .add-friend-icon {
      color: $util-color;
      font-size: 1.3em;
      margin-right: 5px;
    }
  }
}