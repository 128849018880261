@import '../../sass/mixins', '../../sass/variables';

.MessageInboxItem {
 background-color: $dark;
 @include border-radius;
 @include box-shadow;
 box-sizing: border-box;
 margin: 10px 5px 10px 5px;
 padding: 10px;
 color: #fff;
 .messages-link {
   text-decoration: none;
 }
 article {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-around;
  .inbox-profile-img {
    border: 1px solid $util-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    object-fit: contain;
    .inbox-default-user-img {
      color: $util-color;
      font-size: 3em;
    }
  }
  .inbox-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3px;
    color: #FFF;
    .inbox-friend-name {
      display: inline-block;
      font-size: 1em;
    }
    .inbox-last-subject {
      font-size: 1em;
    }
    .inbox-datetime {
      display: flex;
      font-size: .7em;
      .inbox-date {
        margin-right: 10px;
      }
    }
  }
  .inbox-options-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
    .message-delete-button {
      padding: 5px;
      border: 1px solid $util-color;
      border-radius: 3px;
      background-color: $dark;
      font-size: 1em;
      color: #fff;
    }
    .new-message-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .new-message-bubble {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: red;
        margin-right: 5px;
      }
      .new-message-tag {
        display: flex;
        font-size: .8em;
        font-weight: 500;
      }
    }
  }
}
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {
  /* Styles for small devices */
}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .MessageInboxItem {
    width: 60%;
    margin: 10px auto 10px auto;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .MessageInboxItem {
    width: 50%;
    margin: 10px auto 10px auto;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .MessageInboxItem {
    width: 40%;
    margin: 10px auto 10px auto;
  }
}