@import '../../sass/mixins', '../../sass/variables';

.Navbar{
  margin-bottom: 60px;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    z-index: 10000;
    top: 0px;
    background: linear-gradient(180deg, #000, #2b2b2b, #2b2b2b);
    width: 100%;
    color: #fff;
    padding: 10px;
    height: 60px;
    border-top: .3px solid $util-color;
    border-bottom: .3px solid $util-color;
    box-shadow: 0px 0px 10px #000;
    .logo-link {
      text-decoration: none;
      color: #fff;
    }
    .logo-container {
      display: flex;
      .logo-icon {
        margin-right: 3px;
        font-size: 1.1em;
        color: $util-color;
        border: 1px solid $util-color;
        border-radius: 100%;
        padding: 5px;
      }
      span {
        font-size: 1.3em;
        font-weight: 600;
      }
    }
    .navbar-link {
      text-decoration: none;
      display: hidden;
    }
    .nav-friends, .nav-inbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: .8em;
      color: $util-color;
      .navbar-icon {
        font-size: 1.4em;
      }
      label {
        font-size: 1em;
        font-weight: 600;
        color: #fff;
      }
    }
    .navbar-items:nth-child(2) {
      border: 1px solid red;
      position: relative;
    }
    .navbar-messenger {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: .8em;
      color: $util-color;
      .navbar-icon {
        font-size: 1.4em;
      }
      label {
        font-size: 1em;
        font-weight: 600;
        color: #fff;
      }
    }
    .nav-menu-icon {
      font-size: 1.4em;
      color: $util-color;
    }
    .nav-menu-dropdown {
      display: none;
    }
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 55px;
    right: 0;
    z-index: 1000;
    border-radius: 0px 0px 0px 5px;
    background-color: $dark;
    border-left: 1px solid $util-color;
    height: auto;
    width: 200px;
    .menu-items-container {
      .nav-menu-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        .nav-menu-user-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          border: 1px solid $util-color;
          border-radius: 100%;
          margin-bottom: 3px;
          .nav-menu-default-user-image {
            color: $util-color;
            font-size: 3em;
          }
          .nav-menu-user-image {
            border-radius: 100%;
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
        }
        .nav-menu-default-image-container{
          
        }
        .nav-menu-first-name {
          font-size: 1.3em;
          color: #fff;
        }
        .nav-menu-last-name {
          font-size: 1.3em;
          color: #fff;
        }
      }
      li:nth-child(2) {
        border-top: 1px solid $util-color;
      }
      li:nth-child(6) {
        border-radius: 0px 0px 0px 5px;
        border-bottom: 1px solid $util-color;
      }
      li {
        display: flex;
        align-items: center;
        padding: 10px;
        .menu-links {
          text-decoration: none;
          padding: 5px;
          color: #fff;
          font-size: 1.1em;
          font-weight: 600;
          .menu-icon {
            color: $util-color;
            font-size: 1.2em;
            margin-right: 5px;
          }
        }
      }
    }
  }
}


/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Navbar{
    margin-bottom: 60px;
    nav {
      .logo-link {}
      .logo-container {
        .logo-icon {
          font-size: 1.5em;
          padding: 7px;
        }
        span {
          font-size: 1.5em;
          font-weight: 600;
        }
      }
      
      .nav-friends, .nav-inbox {
        .navbar-icon {
          margin-right: 5px;
        }
        label {
          font-size: 1.3em;
        }
      }

      .nav-friends, .nav-inbox {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .navbar-icon {
          font-size: 2em;
          margin-right: 5px;
        }
        label {
          font-size: 1.6em;
          font-weight: 600;
        }
      }  
      .navbar-messenger {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .navbar-icon {
          font-size: 2em;
          margin-right: 5px;
        }
        label {
          font-size: 1.6em;
          font-weight: 600;
        }
      }
      .nav-menu-icon {
        font-size: 2em;
      }
    }
    .nav-menu {
      width: 250px;
      .menu-items-container {
        font-size: 1.3em;
        .nav-menu-header {
          .nav-menu-user-image-container {
            .nav-menu-default-user-image {
            }
            .nav-menu-user-image {}
          }
          .nav-menu-default-image-container{

          }
          .nav-menu-first-name {}
          .nav-menu-last-name {}
        }
        li:nth-child(2) {}
        li:nth-child(6) {}
        li {
          .menu-links {
            .menu-icon {}
          }
        }
      }
    }
  }
}
/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Navbar{
    margin-bottom: 60px;
    nav {
      .logo-link {}
      .logo-container {
        .logo-icon {
          font-size: 1.5em;
          padding: 7px;
        }
        span {
          font-size: 1.5em;
          font-weight: 600;
        }
      }
      .nav-friends, .nav-inbox {
        .navbar-icon {
          margin-right: 5px;
        }
        label {
          font-size: 1.6em;
        }
      }
      .navbar-messenger {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-right: 20px;
        .navbar-icon {
          font-size: 2em;
          margin-right: 5px;
        }
        label {
          font-size: 1.6em;
          font-weight: 600;
        }
      }
      .nav-menu-icon {
        display: none;
      }
      .nav-menu-dropdown {
        display: flex;
        align-items: center;
        padding: 5px;
        margin-right: 10px;
        label{
          font-size: 1.3em;
          font-weight: 500;
          margin-right: 5px;
        }
        .nav-dropdown-icon {
          font-size: 1.3em;
          color: $util-color;
        }
      }
    }
    .nav-menu {
      .menu-items-container {
        font-size: 1.2em;
        .nav-menu-header {
          .nav-menu-user-image-container {
            .nav-menu-default-user-image {
            }
            .nav-menu-user-image {}
          }
          .nav-menu-default-image-container{

          }
          .nav-menu-first-name {}
          .nav-menu-last-name {}
        }
        li:nth-child(2) {}
        li:nth-child(6) {}
        li {
          .menu-links {
            .menu-icon {}
          }
        }
      }
    }
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra-large devices */
}