@import '../../sass/mixins', '../../sass/variables';

.PostEditor {
  @include component-color;
  @include border-radius;
  z-index: 10000;
  box-shadow: 0px 0px 100px 50px #000;
  position: fixed;
  padding: 20px;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .post-editor-header {
    display: flex;
    margin-bottom: 10px;
    .post-editor-header-icon {
      font-size: 1.5em;
      color: $util-color;
      margin-right: 5px;
    }
    h3 {
      color: #fff;
      font-size: 1.3em;
    }
  }
  .edit-inputs-container {
    label {
      display: inline-block;
      color: #fff;
      margin-bottom: 5px;
    }
    textarea {
      @include border-radius;
      @include box-shadow;
      padding: 5px;
      border: none;
      width: 100%;
    }
    textarea:focus {
      @include input-focus;
    }
  }
  .post-edit-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .post-edit-save-button {
      @include border-radius;
      @include box-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 65%;
      padding: 5px;
      background-color: $util-color-2;
      color: #fff;
      font-weight: 600;
      font-size: 1em;
      .post-edit-save-icon {
        margin-right: 5px;
      }
    }
    .post-edit-cancel-button {
      @include border-radius;
      @include box-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 30%;
      padding: 5px;
      background-color: $danger;
      color: #fff;
      font-weight: 600;
      font-size: 1em;
      .post-edit-cancel-icon {
        margin-right: 5px;
      }
    }
  }
}