@import '../../sass/mixins', '../../sass/variables';

.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 1.3em;
    margin: 0px auto 10px auto;
    .login-header-icon {
      color: $util-color;
      font-size: 2em;
      margin-right: 10px;
    }
    h2 {}
  }
  form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 10px 0px 10px;
    background: linear-gradient(80deg, #2b2b2b, #000, #2b2b2b);
    padding: 1.2em;
    color: #fff;
    @include box-shadow;
    @include border-radius;
    label {
      font-size: 1.2em;
      font-weight: 500;
      margin-top: 10px;
      .login-icons{
        color: $util-color;
        margin-right: 8px;
      }
    }
    input {
      @include input-style;
      @include box-shadow;
      @include border-radius;
      padding: 10px;
      margin: 10px 0px 15px 0px; 
    }
    input:focus {
      @include input-focus;
    }
    input::placeholder {
      color: $placeholder-color;
    }
    .alert-container {
      border: 1px solid $util-color-2;
      color: $util-color-2;
      border-radius: 5px;
      padding: 5px;
      margin: 5px 0px 20px 0px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      button {
        @include button-style;
        @include border-radius;
        @include box-shadow;
        margin-top: 15px;
        padding: .2em;
        font-size: 1.3em;
        font-weight: 700;  
      }
      h5 {
        margin: 10px auto 0px auto;
        span {
          color: $util-color-2;
          cursor: pointer;
        }
        span:hover {
          text-decoration: underline;
        }
      }
      hr {
        margin-top: 20px;
        width: 100%;
      }
      h4 {
        margin: 20px auto 10px auto;
      }
      .register-link {
        display: flex;
        border: none;
        justify-content: center;
        text-decoration: none;
        padding: .2em;
        font-size: 1.3em;
        color: #000;
        font-weight: 700;
        color: $util-color-2;
      }
    }
    
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Login {
    form {
      width: 60%;
    }
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Login {
    margin-top: 30px;
    form {
      width: 40%;
    }
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .Login {
    margin-top: 30px;
    form {
      width: 30%;
    }
  }
}