@import '../../sass/variables', '../../sass/mixins';

.GlobalMessenger {
  @include component-color;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  top: 60px;
  height: 100%;
  width: 100%;
  .global-messenger-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #fff;
    .global-messenger-icon {
      color: $util-color;
      font-size: 2em;
      margin-right: 5px;
    }
    .leave-chat-button {
      border: none;
      padding: 5px;
      border-radius: 3px;
      background-color: $util-color;
      color: #fff;
      font-weight: 600;
      margin-left: 30px;
    }
  }
  .messenger-content {
    border: 1px solid $util-color;
    box-sizing: border-box;
    margin: 0px auto 5px auto;
    overflow: scroll;
    width: 95%;
    height: 65%;
    padding: 10px;
    .online-user-count {
      padding:5px;
      color: #fff;
    }
  }
  .global-message-input-container {
    display: flex;
    justify-content: center;
    form {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      input {
        @include input-style;
        @include border-radius;
        @include box-shadow;
        width: 70%;
        font-size: 16px;
        margin-right: 10px;
      }
      input:focus {
        @include input-focus;
      }
      button {
        @include box-shadow;
        border: none;
        border-radius: 3px;
        background-color: $util-color;
        color: #fff;
        font-weight: 600;
        width: 20%;
        .messenger-send-icon {
          margin-right: 3px;
        }
        .leave-chat-button {
          
        }
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .GlobalMessenger {
    .global-messenger-header {
      .global-messenger-icon {
        font-size: 3em;
        margin-right: 30px;
      }
      h2 {
        font-size: 2.5em;
        margin-right: 30px;
      }
      .leave-chat-button {
        padding: 10px;
        float: right;
        font-size: 1.2em;
      }
    }
    .messenger-content {
      width: 80%;
      margin: 10px auto 20px auto;
      .online-user-count {
        padding: 10px;
        font-size: 1.4em;
        color: #fff;
      }
    }
    .global-message-input-container {
      form {
        input {
          box-sizing: border-box;
          width: 60%;
          padding: 15px;
          font-size: 1.2em;
        }
        button {
          width: 20%;
          font-size: 1.3em;
        }
      }
    }
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .GlobalMessenger {
    width: 45%;
    height: 90%;
    position: fixed;
    top: auto;
    bottom: 0px;
    right: 0;
    .global-messenger-header {
      .global-messenger-icon {
        font-size: 2em;
        margin-right: 10px;
      }
      h2 {
        font-size: 1.8em;
        margin-right: 30px;
      }
      .leave-chat-button {
        padding: 5px;
        float: right;
        font-size: 1em;
      }
    }
    .messenger-content {
      width: 90%;
      height: 75%;
      margin: 10px auto 20px auto;
      .online-user-count {
        padding: 10px;
        font-size: 1.4em;
        color: #fff;
      }
    }
    .global-message-input-container {
      form {
        input {
          box-sizing: border-box;
          width: 70%;
          padding: 5px;
          font-size: 1em;
        }
        button {
          width: 20%;
          font-size: 1.3em;
          padding: 5px;
        }
      }
    }
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {}