@import '../../sass/variables', '../../sass/mixins';

.ProfileHeader {
  background-color: $dark;
  margin-top: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px 10px 5px;
  .user-header-info {
    display: flex;
    align-items: center;
    height: 100px;
    .user-image-header-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin-right: 15px;
      border: 2px solid $util-color;
      background-color: $dark;
      border-radius: 100%;
      .header-image-edit-icon {
        color: #fff;
        position: absolute;
        z-index: 1;
        font-size: .7em;
        border: 1px solid $util-color;
        padding: 7px;
        border-radius: 100%;
        background-color: $dark;
        bottom: -5px;
        right: -7px;
      }
      .header-user-icon {
        font-size: 3.5em;
        color: $util-color;
      }
    }
    .header-user-details {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 20px;
      height: 100%;
      .header-username {
        color: #fff;
        font-weight: 600;
        font-size: 2em;
      }
    }
  }
  .image-upload-modal {
    @include component-color;
    @include border-radius;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 10px;
    width: 90%;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    .upload-user-image-buttons {
      display: flex;
      margin-top: 20px;
      justify-content: space-around;
      .save-user-image {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        @include border-radius;
        @include box-shadow;
        background-color: $util-color-2;
        width: 60%;
        padding: 10px;
        color: #fff;
        font-size: 1em;
        font-weight: 700;
      }
      .cancel-user-image {
        border: none;
        @include border-radius;
        @include box-shadow;
        background-color: $danger;
        color: #fff;
        font-size: 1em;
        font-weight: 700;
        width: 30%;
        padding: 10px;
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {
  /* Styles for small devices */
}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .ProfileHeader {
    .user-header-info {
      .user-image-header-container {
        .header-image-edit-icon {
        }
        .header-user-icon {}
      }
      .header-user-details {
        .header-username {
          font-size: 2em;
        }
      }
    }
    .image-upload-modal {
      width: 50%;
      .upload-user-image-buttons {
        .save-user-image {}
        .cancel-user-image {}
      }
    }
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .ProfileHeader {
    .user-header-info {
      .user-image-header-container {
        .header-image-edit-icon {
        }
        .header-user-icon {}
      }
      .header-user-details {
        .header-username {
          font-size: 2em;
        }
      }
    }
    .image-upload-modal {
      width: 40%;
      .upload-user-image-buttons {
        .save-user-image {}
        .cancel-user-image {}
      }
    }
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .ProfileHeader {
    .user-header-info {
      .user-image-header-container {
        .header-image-edit-icon {
        }
        .header-user-icon {}
      }
      .header-user-details {
        .header-username {
          font-size: 2em;
        }
      }
    }
    .image-upload-modal {
      width: 30%;
      .upload-user-image-buttons {
        .save-user-image {}
        .cancel-user-image {}
      }
    }
  }
}