@import '../../sass/mixins', '../../sass/variables';

.Profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark;
  @include border-radius;
  @include box-shadow;
  padding: 15px;
  margin: 10px 5px 20px 5px;
  width: 60%;
  // adust width to below at tablet
  // width: 25%;
    .profile-card-user-image-container{
      width: 125px;
      height: 125px;
      border: 1px solid $util-color;
      border-radius: 100%;
    }
    .profile-card-default-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 125px;
      height: 125px;
      border: 1px solid $util-color;
      border-radius: 100%;
      .profile-card-default-icon {
        color: $util-color;
        font-size: 5em;
      }
    }
  figcaption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .profile-view-link {
      text-decoration: none;
      cursor: pointer;
      .profile-card-name {
        color: #fff;
        margin-top: 10px;
        font-size: 1.5em;
        font-weight: 600;
      }
    }
    .profile-card-city-info {
      color: #fff;
      font-size: 1.2em;
    }
    .profile-card-occupation {
      color: #fff;
      margin-bottom: 10px;
      font-size: 1.1em;
    }
  }
}


/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Profile {
    width: 25%;
    margin: 10px;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Profile {
    width: 20%;
    margin: 10px;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra-large devices */
}