@import '../../sass/mixins', '../../sass/variables';

.ImageUploader {
  .image-uploader-header {
    box-sizing: border-box;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
    padding: 5px;
    .choose-image-icon {
      color: $util-color;
      margin-right: 10px;
      font-size: 1.5em;
    }
    h3 {
      color: #fff;
    }
    span {
      padding: 5px;
    }
    .upload-check-icon {  
      font-size: 1em;
      border: 2px solid $util-color;
      color: $util-color;
      padding: 5px;
      border-radius: 100%;
    }
  }
  form {
    .custom-file-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 100%;
        @include border-radius;
        border: 2px solid $util-color-2;
        .upload-file-icon {
          color: $util-color;
          font-size: 5em;
        }
      }
      input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap; /* 1 */
        clip-path: inset(50%);
        border: 0;
      }
    }
  }
  .image-preview-container {
    position: relative;
    @include border-radius;
    @include box-shadow;
    height: 200px;
    width: 100%;
    .cancel-image {
      border: 1.5px solid $util-color-2;
      border-radius: 0px 5px 0px 0px;
      padding: 1em;
      background-color: $dark;
      color: $danger;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    img {
      @include border-radius;
      object-fit: cover;
      height: 200px;
      width: 100%;
    }
  }
}