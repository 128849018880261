@import '../../sass/mixins', '../../sass/variables';

.Message {
  margin: 30px 5px 0px 5px;
  Article {
    @include border-radius;
    @include box-shadow;
    background-color: #fff;
    .message-header {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 5px 5px 0px 0px;
      @include component-color;
      padding: 10px;
      .message-profile-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 1px solid $util-color;
        border-radius: 100%;
        margin-right: 10px;
        .message-default-user-image {
          color: $util-color;
          font-size: 2em;
        }
      }
      .message-sender-name {
        color: $util-color;
      }
      .message-reply-datetime {
        display: flex;
        color: #fff;
        .message-reply-date {
          margin-right: 10px;
        }
        .message-reply-time {}
      }
    }
    .message-content {
      padding: 10px;
      h2 {
      
      }
      p {
        font-size: 1.1em;
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Message {
    width: 60%;
    margin: 30px auto 0px auto;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Message {
    width: 50%;
    margin: 30px auto 0px auto;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .Message {
    width: 40%;
    margin: 30px auto 0px auto;
  }
}