@import '../../sass/mixins', '../../sass/variables';

.OptionsModal {
  box-sizing: border-box;
  @include component-color;
  @include border-radius;
  box-shadow: 0px 0px 5px 3px #000;
  padding: 20px;
}
.OptionsModal::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 75%;
  width: 0px;
  height: 0px;
  border: 10px solid transparent;
  border-bottom: 10px solid $util-color;
}

.comment-style {
  position: absolute;
  bottom: -146px;
  right: -11px;
}

.post-style {
  position: absolute;
  bottom: -140px;
  right: -8.5px;
}