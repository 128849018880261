.ProfileCards {
  margin-top: 120px;
  .profile-cards-container {
    margin: 200px auto 0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}


/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .ProfileCards {
    margin-top: 120px;
    .profile-cards-container {
      margin: 200px auto 0px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  /* Styles for large devices */
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra-large devices */
}