@import '../../sass/mixins', '../../sass/variables';

.PageTitle {
  display: flex;
  align-items: center;
  background-color: $dark;
  box-sizing: border-box; 
  position: fixed;
  width: 100%;
  top: 60px;
  padding: 20px;
  .page-title-icon {
    color: $util-color;
    margin-right: 5px;
    font-size: 1.8em;
  }
  .page-title-text {
    font-size: 1.5em;
    color: #fff;
    font-weight: 600;
  }
}