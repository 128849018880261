@import '../../sass/mixins', '../../sass/variables';

.MessageInbox {
  margin-top: 150px;
  .message-inbox-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark;
    @include box-shadow;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    margin: -20px 0px 30px 0px;
    padding: 20px;
    .message-inbox-icon {
      color: $util-color;
      font-size: 1em;
      margin-right: 10px;
    }
    h3 {
      color: #fff;
    }
    .message-add-icon {
      border-radius: 100%;
      color: $util-color-2;
      margin-right: 5px;
    }
    .create-message-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $dark;
      display: flex;
      justify-content: center;
      border: 1px solid $util-color;
      border-radius: 3px;
      padding: 5px;
      color: #fff;
      // font-weight: 600;
      cursor: pointer;
      .create-message-button-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $util-color;
        margin-right: 3px;
      }
    }
  }
  .message-inbox-container {
    padding-top: 100px;
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .message-inbox-toolbar {
    width: 60%;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .message-inbox-toolbar {
    width: 50%;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .message-inbox-toolbar {
    width: 40%;
  }
}