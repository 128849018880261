@import '../../sass/mixins', '../../sass/variables';

.FriendAccept {
  .friend-tag-container {
    .friend-tag {
      @include friend-tag-style;
      .friend-tag-icon {
        @include friend-tag-icon;
      }
    }
  }
  .accept-decline-requests-container {
    .friend-accept-button {
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius;
      padding: 5px;
      margin-bottom: 10px;
      border: 1px solid $util-color;
      background-color: $dark;
      width: 100%;
      color: #fff;
      font-size: 1em;
      font-weight: 600;
      .friend-accept-icon {
        color: $util-color;
        font-size: 1.3em;
        margin-right: 5px;
      }
    }
    .friend-decline-button {
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius;
      padding: 5px;
      border: 1px solid $util-color;
      background-color: $dark;
      width: 100%;
      color: #fff;
      font-size: 1em;
      font-weight: 600;
      .friend-decline-icon {
        color: $util-color;
        font-size: 1.3em;
        margin-right: 5px;
      }
    }
  }
}