@import '../../sass/mixins', '../../sass/variables';

.valid {
  color: $util-color-2;
}

.invalid {
  color: #ff3860;
}

.default {
  color: #fff;
}

.Register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 80%;
    @include box-shadow;
    @include border-radius;
    @include component-color;
    padding: 40px;
    margin: 10px 5px;
    header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .back-arrow {
        display: flex;
        justify-content: baseline;
        align-items: center;
        text-decoration: none;
        color: $util-color;
        font-size: 2em;
        margin-right: 20px;
      }
      h2 {
        color: #fff;
      }
    }
    label {
      display: flex;
      justify-content: baseline;
      margin: 20px 0px 5px 0px;
      color: #fff;
      font-size: 1.3em;
      font-weight: 500;
      .label-icons {
        color: $util-color;
        margin-right: 8px;
      }
    }
    input {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      @include input-style;
      @include border-radius;
      @include box-shadow;
    }
    input:focus {
      @include input-focus
    }
    span {
      margin: 5px 0px 0px 0px;
    }
    .register-button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      margin-top: 20px;
      width: 100%;
      button {
        width: 150px;
        padding: 5px;
        border: none;
        background-color: $util-color;
        color: #fff;
        font-size: 1.2em;
        font-weight: 600;
        @include border-radius;
        @include box-shadow;
      }
      .cancel-registration {
        text-decoration: none;
        color: $util-color-2;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {
  .Register {
    margin-top: 40px;
  }
}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .Register {
    width: 60%;
    margin: 0px auto;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .Register {
    width: 40%;
    margin: 0px auto;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .Register {
    width: 30%;
    margin: 0px auto;
  }
}