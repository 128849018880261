@import '../../sass/mixins', '../../sass/variables';

.Search {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: $dark;
  @include box-shadow;
  position: fixed;
  top: 120px;
  padding: 10px;
  width: 100%;
  span {
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .search-icon {
      @include box-shadow;
      background-color: $util-color;
      color: $dark;
      font-size: 1.3em;
      border-radius: 5px 0px 0px 5px;
      padding: 5px;
    }
    .search-input {
      @include box-shadow;
      font-size: 16px;
      padding: 5px;
      width: 80%;
      border: 1px inset transparent;
      border-radius: 0px 5px 5px 0px;
    }
    .search-input:focus {
      outline: 1px inset $util-color;
    }
  }
}