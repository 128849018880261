@import '../../sass/mixins', '../../sass/variables';

.MessageCreate {
  @include component-color;
  @include border-radius;
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: 0px 5px 0px 5px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 1000;
  width: 80%;
  box-shadow: 0px 0px 100px 50px #000;
  padding: 25px;
  .message-create-header {
    display: flex;
    align-items: flex-end;
    .message-create-header-icon {
      color: $util-color;
      font-size: 2em;
      margin-right: 10px;
    }
    h2 {
      color: #fff;
    }
  }
  label {
    color: #fff;
    margin: 20px 0px 10px 0px;
  }
  .select-friend-input, .subject-input, .message-input {
    @include border-radius;
    @include box-shadow;
    @include input-style;
  }
  .friend-list-dropdown {
   @include component-color;
   box-shadow: 0px 2px 2px 2px #000;
   position: relative;
   min-height: 50px;
   max-height: 200px;
   overflow: scroll;
   padding: 5px;
  }
  .message-create-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
    .message-cancel {
      background-color: $dark;
      border: none;
      color: $util-color-2;
      font-size: 1em;
      font-weight: 600;
      .message-cancel-icon {
        margin-right: 5px;
      }
    }
    .message-send-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border: 2px solid $util-color;
      border-radius: 3px;
      color: #fff;
      background-color: $dark;
      font-weight: 600;
      font-size: 1em;
      width: 60%;
      margin-right: 5px;
      .message-send-icon {
        margin-right: 5px;
        color: $util-color;
      }
    }
    .message-cancel-button {
      
      .message-cancel-icon {
        margin-right: 5px;
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {
  /* Styles for small devices */
}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .MessageCreate {
    width: 50%;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .MessageCreate {
    width: 40%;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .MessageCreate {
    width: 30%;
  }
}
