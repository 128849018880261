@import '../../sass/mixins', '../../sass/variables';

.Messages {
  margin-top: 60px;
  .message-toolbar {
    display: flex;
    align-items: center;
    background-color: $dark;
    padding: 20px;
    justify-content: space-between;
    .message-inbox-link {
      text-decoration: none;
    }
    .message-back {
      font-size: 1.3em;
      color: #fff;
      .message-back-arrow-icon {
        color: $util-color;
        margin-right: 5px;
      }
    }
    .message-reply {
      color: #fff;
      font-size: 1.3em;
    }
    .message-reply-icon {
      color: $util-color;
      margin-right: 5px;
    }
  }
  .message-reply-container {
    @include component-color;
    @include border-radius;
    @include box-shadow;
    padding: 20px;
    margin: 20px 5px 20px 5px;
    .message-reply-header {
      display: flex;
      align-items: center;
      .message-reply-header-icon {
        color: $util-color;
        font-size: 1.3em;
        margin-right: 5px;
      }
      h2 {
        color: #fff;
      }
    }
    textarea {
      @include input-focus;
      border-radius: 3px;
      margin: 10px 0px 10px 0px;
      width: 100%;
    }
    .message-reply-buttons {
      display: flex;
      justify-content: space-around;
      border: none;
      .message-reply-send-button, .message-reply-cancel-button {
        padding: 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-weight: 700;
      }
      .message-reply-send-button {
        background-color: $util-color-2;
        width: 65%;
        margin-right: 10px;
        .message-reply-send-icon {
          margin-right: 3px;
        }
      }
      .message-reply-cancel-button {
        background-color: $danger;
        width: 30%;
        .message-reply-cancel-icon {
          margin-right: 3px;
        }
      }
    }
  }
}

/* Media query for small devices (e.g., phones) */
@media only screen and (min-width: 320px) {}

/* Media query for medium devices (e.g., tablets) */
@media only screen and (min-width: 768px) {
  .message-reply-container {
    width: 60%;
    margin: 20px auto !important;
  }
}

/* Media query for large devices (e.g., laptops and desktops) */
@media only screen and (min-width: 1024px) {
  .message-reply-container {
    width: 50%;
    margin: 20px auto !important;
  }
}

/* Media query for extra-large devices (e.g., large desktops and TVs) */
@media only screen and (min-width: 1200px) {
  .message-reply-container {
    width: 40%;
    margin: 20px auto !important;
  }
}