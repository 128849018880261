@import '../../sass/mixins', '../../sass/variables';

.PostEdit {
  .post-edit-button {
    display: flex;
    align-items: center;
    border: 1px solid $util-color;
    @include border-radius;
    @include component-color;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
    .post-edit-icon {
      color: $util-color;
      margin-right: 5px;
      font-size: 1.3em;
    }
  }
}