@import './variables';

//Component styles
@mixin box-shadow {
  box-shadow: 3px 3px 3px #000;
}

@mixin modal-box-shadow {
  box-shadow: 0px 0px 1000px 100px #000;
}

@mixin border-radius {
  border-radius: 5px;
}

@mixin component-color {
  background: linear-gradient(80deg, #2b2b2b, #000, #2b2b2b);
}

//Inputs / TextAreas
@mixin input-style {
  border: 2px solid transparent;
  caret-color: $util-color;
  padding: 5px;
}

@mixin input-focus {
  outline: 2px solid $util-color;
}

@mixin letter-spacing {
  letter-spacing: .05em;
}

//Button Styles
@mixin button-style {
  border: none;
  color: $button-text;
  background-color: $util-color;
  border-radius: 3px;
}


//Friends Tag Style

@mixin request-sent-style {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  font-size: 1.1em;
  font-weight: 600;
}

@mixin request-sent-icon {
  color: $util-color; 
  margin-right: 5px;
  font-size: 1.3em;
}

@mixin friend-tag-style {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  font-size: 1.2em;
  padding: 10px;
  font-weight: 600;
}

@mixin friend-tag-icon {
  color: $util-color;
  font-size: 1.3em;
  margin-right: 5px;
}

